import {collection, doc, setDoc, Timestamp} from "firebase/firestore";
import {firestore} from "../firebase";

export const addUserToFirestore = async (email: string) => {
  const docRef = doc(collection(firestore, "smoke-test-users"));

  await setDoc(docRef, {
    email,
    created_at: Timestamp.now(),
  });
};

// src/services/firebase.ts
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAxcondeomVyMk7zMwrcxLgWyuhldPadoU",
  authDomain: "gorilla-prod-b3f96.firebaseapp.com",
  projectId: "gorilla-prod-b3f96",
  storageBucket: "gorilla-prod-b3f96.appspot.com",
  messagingSenderId: "885990322920",
  appId: "1:885990322920:web:b7b22262d918d555b5cf5d",
  measurementId: "G-W7P285E3BR",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

export {app, firestore};

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Button, Form, Image, Row, Col, InputGroup} from "react-bootstrap";
import LOGO from "../assets/logo/logo-full-black.svg";
import STRIPE_POWERED from "../assets/stripe-powered.svg";
import {addUserToFirestore} from "../services/firestore/smoke-test-user";
import VISA_IMAGE from "../assets/cards/visa.svg";
import MASTERCARD_IMAGE from "../assets/cards/mastercard.svg";
import AMEX_IMAGE from "../assets/cards/amex.svg";
import CVV_IMAGE from "../assets/cards/cvv.png";

const PaymentPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCvc, setCardCvc] = useState("");
  const [cardError, _] = useState("");
  const [isCardValid, setIsCardValid] = useState(false);

  // Nuevos estados
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // Validación de email básica
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(emailValue));
  };

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCardNumber(value);

    // Validación simple de longitud de tarjeta de crédito
    if (value.length >= 2) {
      setIsCardValid(true);
    } else {
      setIsCardValid(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Aquí puedes manejar la lógica de envío y validación
    console.log("Correo:", email);
    console.log("Número de tarjeta:", cardNumber);
    console.log("Fecha de caducidad:", cardExpiry);
    console.log("CVC:", cardCvc);
  };

  const saveUserEmailAndRedirect = async () => {
    await addUserToFirestore(email);

    window.location.href = "https://google.com";
  };

  useEffect(() => {
    if (isCardValid) {
      saveUserEmailAndRedirect();
    }
  }, [isCardValid]);

  return (
    <Form onSubmit={handleSubmit} className="p-4">
      <div className="text-center mb-4">
        <Image src={LOGO} alt="Logo" width="200" />

        <div className="d-flex w-100 justify-content-center mb-3">
          <Image
            src={STRIPE_POWERED}
            alt="Powered by Stripe"
            width="100"
            className="mt-1"
          />
        </div>
      </div>

      {/* Concepto del pago */}
      <Row className="mb-3">
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <h5 className="text-gray500">Suscribirse al Plan Básico</h5>
          <div className="d-flex flex-row gap-1 align-items-baseline">
            <span className="fw-bold fs-1">30€</span>
            <span className="text-gray500">al año</span>
          </div>
          <span className="text-gray500" style={{fontSize: "8px"}}>
            (IVA incl.)
          </span>
        </Col>
      </Row>

      <Row>
        <Col>
          <span className="fw-bold fs-4">Datos de pago</span>
        </Col>
      </Row>
      {/* Correo */}
      <Form.Group className="mb-3">
        <Form.Label>Correo electrónico</Form.Label>
        <Form.Control
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Introduce tu correo"
        />
        {!isValidEmail && email && (
          <Form.Text className="text-danger">
            Por favor, introduce un correo válido
          </Form.Text>
        )}
      </Form.Group>

      <div className={`fade-in ${isValidEmail ? "show" : ""}`}>
        <Row>
          <Col>
            <span className="fw-bold fs-6">Detalles de la tarjeta</span>
          </Col>
        </Row>
        <Form.Group>
          <InputGroup>
            <Form.Control
              type="text"
              value={cardNumber}
              onChange={handleCardNumberChange}
              placeholder="1234 1234 1234 1234"
              maxLength={19}
              style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}
            />

            <InputGroup.Text
              className="d-flex flex-row gap-2"
              style={{borderBottomRightRadius: 0}}
            >
              <Image src={VISA_IMAGE} alt="Visa" width="30" />
              <Image src={MASTERCARD_IMAGE} alt="Mastercard" width="30" />
              <Image src={AMEX_IMAGE} alt="American Express" width="30" />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3 d-flex flex-row">
          <Form.Control
            type="text"
            value={cardExpiry}
            onChange={(e) => setCardExpiry(e.target.value)}
            placeholder="MM/YY"
            maxLength={5}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 0,
            }}
          />

          <InputGroup>
            <Form.Control
              type="text"
              value={cardCvc}
              onChange={(e) => setCardCvc(e.target.value)}
              placeholder="CVC"
              maxLength={4}
            />
            <InputGroup.Text style={{borderTopRightRadius: 0}}>
              <Image src={CVV_IMAGE} alt="CVV" width="30" />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        {cardError && <div className="text-danger">{cardError}</div>}

        <Form.Group className="mb-3 d-flex flex-row gap-2 align-items-center">
          <Form.Check
            type="checkbox"
            id="termsCheckbox"
            checked={isTermsAccepted}
            onChange={() => setIsTermsAccepted(!isTermsAccepted)}
            required
          />
          <label htmlFor="termsCheckbox" className="m-0 align-items-center">
            Acepto los{" "}
            <a href="/terms" target="_blank" rel="noopener noreferrer">
              Términos y Condiciones
            </a>
          </label>
        </Form.Group>

        <Form.Group className="mb-3 d-flex flex-row gap-2 align-items-center">
          <Form.Check
            type="checkbox"
            id="privacyCheckbox"
            checked={isPrivacyAccepted}
            onChange={() => setIsPrivacyAccepted(!isPrivacyAccepted)}
            required
          />
          <label htmlFor="privacyCheckbox" className="m-0 align-items-center">
            Acepto la{" "}
            <a href="/privacy" target="_blank" rel="noopener noreferrer">
              Política de Privacidad
            </a>
          </label>
        </Form.Group>

        {/* Botón de pago */}
        <div className="d-flex flex-column w-100 justify-content-center align-items-center">
          <Button
            type="submit"
            className="w-100"
            disabled={
              !isValidEmail ||
              !isCardValid ||
              !isTermsAccepted ||
              !isPrivacyAccepted
            }
          >
            Pagar y suscribirme
          </Button>
          <div className="p-2 fw-light text-center" style={{fontSize: "12px"}}>
            Al pagar y suscribirte, nos permites cobrarte anualmente la cantidad
            reflejada de acuerdo a nuestros Términos y Condiciones.
            <br />
            Puedes cancelar esta suscripción cuando quieras.
          </div>
        </div>
      </div>
    </Form>
  );
};

export default PaymentPage;

import "./App.css";
import PaymentPage from "./components/PaymentPage";

function App() {
  return (
    <div className="App">
      <PaymentPage />
    </div>
  );
}

export default App;
